var env="product"
  var version ="2.0.4"
  var app_setting ={
    "dev":{
        "serverhost":"http://127.0.0.1:8081"
    },
    "devnginx":{
        "serverhost":"http://127.0.0.1"
    },
    "product":{
       
        "serverhost":"https://www.cosmosatom.cc"
    
    }}
  const configure_options={
"env":env,
"version":version,
"app_setting":app_setting
}
 


// var  app_settting={
//     "dev":{
//         "serverhost":"http://127.0.0.1:8081"
//     },
//     "dev_with_niginx":{
//         "serverhost":"http://127.0.0.1"
//     },
//     "product":{
//         "serverhost":"https://www.blackboxo.top"
//     },
// }

export default configure_options
